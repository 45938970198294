const btnBack = $('#back-top');

const HomePage = (function () {
    let modules = {};

    modules.scrollFunction = function () {
        if (isNotEmpty(btnBack.style) && document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
            btnBack.style.display = "block";
        } else if(isNotEmpty(btnBack.style)) {
            btnBack.style.display = "none";
        }
    }

    modules.topFunction = function topFunction() {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }

    return modules;
})(window.jQuery, window, document);

$(document).ready(function () {
    window.onscroll = function() {HomePage.scrollFunction()};
    btnBack.on('click', function () {
        HomePage.topFunction();
    })
})

export {HomePage}
